import React, { useState,useEffect } from 'react';
import { Card, Table, Drawer,Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { navigate } from 'gatsby';

const TicketList = ({ticketData}) => {
const yardNavigate=()=>{
  localStorage.setItem('yard',0); navigate(`/myYard`);
}
  const columns = [
    {
      title: 'TICKET',
      dataIndex: 'ticket_details',
      width: 30,
      align: 'center',
      render: (_, root) => (
        <div style={{cursor:'pointer'}} onClick={()=>yardNavigate()}>{root.ticket_details ? root.ticket_details: 'NA'}</div>
      ),
      // sorter: {
      //   compare: (a, b) => {
      //     a = a.ticket_details;
      //     b = b.ticket_details;
      //     return a > b ? -1 : b > a ? 1 : 0;
      //   },
      // },
    },
    {
      title: 'EQUIPMENT',
      dataIndex: 'equipment_no',
      width: 45,
      align: 'center',
      render: (_, root) => (
        <div style={{cursor:'pointer'}} onClick={()=>yardNavigate()}>{root.equipment_no}</div>
      ),
      // sorter: {
      //   compare: (a, b) => {
      //     a = a.equipment_no.toLowerCase ();
      //     b = b.equipment_no.toLowerCase ();
      //     return a > b ? -1 : b > a ? 1 : 0;
      //   },
      // },
    },
    {
      title: 'CUST. NAME',
      dataIndex: 'customer',
      width: 45,
      align: 'center',
      render: (_, root) => (
        <div style={{cursor:'pointer'}} onClick={()=>yardNavigate()}>{root.customer}</div>
      ),
      // sorter: {
      //   compare: (a, b) => {
      //     a = a.customer.toLowerCase ();
      //     b = b.customer.toLowerCase ();
      //     return a > b ? -1 : b > a ? 1 : 0;
      //   },
      // },
    },
    {
      title: 'DAYS',
      dataIndex: 'days_in_yard',
      width: 20,
      align: 'center',
      render: (_, root) => (
        <div style={{cursor:'pointer'}} onClick={()=>yardNavigate()}>{root.days_in_yard}</div>
      ),
      sorter: {
        compare: (a, b) => {
          a = a.days_in_yard;
          b = b.days_in_yard;
          return a > b ? -1 : b > a ? 1 : 0;
        },
      },
    },
    // {
    //   title: 'NOTES',
    //   dataIndex: 'days_in_yard',
    //   align: 'center',
    //   sorter: {
    //     compare: (a, b) => {
    //       a = a.days_in_yard;
    //       b = b.days_in_yard;
    //       return a > b ? -1 : b > a ? 1 : 0;
    //     },
    //   },
    // },
   
  ];
  
  const data = [];
  ticketData &&
    ticketData.map((item, index) => {
      const customer=item?.asset_details?.customer_details
      data.push({
        key: index,
        ticket_details: item?.ticket_details,
        equipment_no:item?.asset_details?.equipment_no,
        customer:customer?.name,
        days_in_yard: item?.days_in_yard,
        // note: item?.days_in_yard,
  
  
      });
    });
  return (
    <Card>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ y: 213 }}
        pagination={false}
      />
      <style>{}</style>
    </Card>
  );
};
export default TicketList;
