import React from 'react'
import "./pageHeader.css"
import Register from '../EquipmentCheck/EquipmentCheckIn'
import CheckOut from '../EquipmentCheck/Equip_Check_Out'
import FoodCheckin from '../EquipmentCheck/FoodCheckin';
import{IndustryType, useWindowDimensions} from '../../utils/helper'
const PageHeader = (props) => {
  const companyName = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('data')) : '';
    const equipmentDrop=()=>{}
    const equipmentPick=()=>{}
  return (
    <section className='wash_page_header'>
      <div className='wash_page_header_head' style={{textTransform:props.title==='po list'?'':'capitalize'}}>
          {props.title} 
          {props.button?
          <div className='wash_page_header_button'>{props.buttonContent}</div>
          :
          props.img?<div className='wash_page_header_add' onClick={props.addHandler}>
              <img src={props.img} alt="add icon"/>
              <span>{props.title2}</span>
          </div>:""}
      </div>
      <div className='wash_page_header_btn'>
         <div>
          {(companyName?.role_id && companyName.role_id !== 999 &&typeof window !== 'undefined')&&IndustryType()!=="Food_Grade"? 
          <Register custom={true} className="wash_sam_popups_1" clients_cust_id={1}  {...props}  buttonColor={'default'} Button={<span>Equipment Drop-Off</span>} title="EQUIPMENT DROP-OFF" />:
          <FoodCheckin custom={true} className="wash_sam_popups_1" clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>Equipment Drop-Off</span>} title="EQUIPMENT DROP-OFF" />}   
          </div>
          <div>
            {companyName?.role_id && companyName.role_id !== 999 && <CheckOut custom={true} className="wash_sam_popups_2" clients_cust_id={1}  {...props} buttonColor={'default'} Button={<span>Equipment Pick-Up</span>} title="EQUIPMENT PICK-UP" />}
          </div>
        </div>
    </section>
  )
}

export default PageHeader